import React from 'react';
import Iff from '../Iff';

// Ripped from https://github.com/encryption/react-read-more because we needed to customize it

class ReadMore extends React.Component {
  constructor({ initialHeight }) {
    super();
    this.state = { initialHeight, maxHeight: initialHeight };
  }

  toggle() {
    let { maxHeight, initialHeight } = this.state;

    //if expanded, close it
    if (maxHeight !== initialHeight)
      return this.setState({ maxHeight: initialHeight });

    let height = this.calculateHeight();

    //set the full height
    this.setState({ maxHeight: height });
  }

  calculateHeight() {
    //calculate height of all the children
    let children = [...this.container.children];
    let height = 0;
    children.forEach(child => (height += child.offsetHeight));

    return height;
  }

  componentDidMount() {
    if (this.calculateHeight() <= this.props.initialHeight)
      this.setState({ hideReadMore: true });
  }

  render() {
    let { children, readMore, overhangSize } = this.props;
    let { maxHeight, initialHeight, hideReadMore } = this.state;
    let open = maxHeight !== initialHeight;

    return (
      <React.Fragment>
        <div
          className="readmore container"
          style={{
            maxHeight: open ? maxHeight : maxHeight - overhangSize,
            transition: 'max-height .5s ease',
            position: 'relative',
            overflow: 'hidden',
          }}
          ref={el => (this.container = el)}
        >
            <Iff condition={open}>
                {children}
            </Iff>
            <Iff condition={!open}>
                <div className="excerpt" style={{position: "absolute"}} dangerouslySetInnerHTML={{__html: this.props.excerpt}} />
                <div className="spacer" style={{visibility: "hidden"}}>{children}</div>
            </Iff>
        </div>
        {hideReadMore
          ? null
          : readMore({
              open,
              onClick: () => this.toggle(),
            })}
      </React.Fragment>
    );
  }
}

ReadMore.defaultProps = {
  overhangSize: 160,
}

export default ReadMore;