import React from 'react';
import './style.scss';
import Wrapper from '../Wrapper/Wrapper';
import SEO from '../../components/seo';
import Img from 'gatsby-image';
import circleMask from "../../assets/images/circle-mask.png";
//import ShowMore from 'react-show-more';
//import ReadMoreAndLess from 'react-read-more-less';
import ReadMore from '../../components/ReadMore/ReadMore';
import Columns from '../../components/Columns/Columns';
import EmailSignUp from '../../components/Forms/EmailSignUp/EmailSignUp';
import ArrowIcon from '../../assets/images/arrow.svg';

const TeamHome = ({pageContext}) => {
    console.log('team pageContext ',pageContext);
    
    const listTeam = pageContext.allEdges.map(edge => {
        console.log('edge ',edge.node);
        
        return(
           
            <div className="blockwrap list-team">
                <div className="container">
                    <div className="content">

                        <div className="list-item">
                            <Columns>
                            <div className="ft-img-wrap">
                                <img src={circleMask} className="circle-mask" alt="circle masking out" />
                                <Img fluid={edge.node.featured_media.localFile.childImageSharp.fluid} className="clip-circle" alt="Featured Post"  />
                            </div>
                            <div className="info">
                                <h2>{edge.node.title}</h2>
                                <h3>{edge.node.acf.team_title}</h3>
                                {/*  <ShowMore
                                    lines={7}
                                    more='Expand Bio'
                                    less='Close'
                                    anchorClass=''
                                >
                                    <p dangerouslySetInnerHTML={{__html: edge.node.content }}></p>
                                </ShowMore> */}
                                <ReadMore
                                    initialHeight={350}
                                    readMore={props => (
                                        <div className={"more-btn " + (props.open ? 'down' : 'up')} onClick={props.onClick}>
                                        <ArrowIcon /><span>{props.open ? 'Minimize Bio' : 'Expand Bio'}</span>
                                        </div>
                                    )}
                                    excerpt={edge.node.excerpt}
                                    >
                                   {/*  <Label>The Design Vision</Label> */}
                                    
                                    <div className="base-styles e" dangerouslySetInnerHTML={{__html: edge.node.content }} />
                                    
                                    </ReadMore>
                             
                            </div>
                            </Columns>
                        </div>

                    </div>
                </div>
            </div>
           
        )
    })

    let emailSignUp = pageContext.thisEdge.acf.email_sign_up ? <EmailSignUp groupid={pageContext.thisEdge.acf.groupid} cta_text={pageContext.thisEdge.acf.cta_text} /> : null;
    

    return(
        <Wrapper pageCurrent={ `team-home ${pageContext.thisEdge.title.toLowerCase()}` }>
            <SEO title={pageContext.thisEdge.title} />
        
            <div className="blockwrap menu-spacer">
                
                <div className="container base-styles">
                
                    <div className="content">
                        <div className="inner-heading-wrap">
                            <h1 className="animate-underline">{pageContext.thisEdge.title}</h1>
                        </div>
                        <div className="e" dangerouslySetInnerHTML={{__html: pageContext.thisEdge.content}} />

                    </div>
                    
                    
                </div>

               
            </div>
            {listTeam}
            {emailSignUp}
        
        </Wrapper>

    )
}
export default TeamHome;